.circle-loader {
  @apply relative flex items-center justify-center w-18 h-18;

  & svg {
    @apply absolute;
  }

  & circle {
    stroke: rgb(var(--border-color-stroke-theme1-base));
  }
}
