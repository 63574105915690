@import "tailwindcss/base.css";
@import "tailwindcss/components.css";
@import "tailwindcss/utilities.css";

@import "./styles/variables.pcss";
@import "./styles/background.pcss";
@import "./styles/layouts.pcss";
@import "./styles/adlayouts.pcss";
@import "./styles/tve-layout.pcss";
@import "./styles/footer.pcss";

@import "./components/circle-loader.pcss";
@import "./components/link.pcss";
@import "./components/line-item.pcss";
@import "./components/prism-box.pcss";

@import "./styles/legacy-overrides.pcss";
